import { Component, OnInit } from "@angular/core";
import { debounceTime } from "rxjs/operators";
import { Subject } from "rxjs";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MasterTenantsService } from "../../../services/master-tenants.service";
import { ActivatedRoute } from "@angular/router";
import { EditDetailComponent } from "../edit-detail/edit-detail.component";
import { MatDialog } from "@angular/material/dialog";
import { environment } from "../../../../../environments/environment";
import { FeaturedListingService } from "../../../../core/services/featured-listing.service";
import { I18nService } from "../../../services";
import * as moment from "moment";
import { PropertyService } from "../../../services/property.services";

@Component({
  selector: "app-tenants-detail",
  templateUrl: "./tenants-detail.component.html",
  styleUrls: ["./tenants-detail.component.scss"]
})
export class TenantsDetailComponent implements OnInit {
  data;
  list = [];
  id;
  isAdmin = false;
  isDetail = false;
  showContactMap = new Map<number, boolean>();
  showOwnerMap = new Map<number, boolean>();
  spotVacancy;

  constructor(
    private masterTenantsService: MasterTenantsService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private featureListingService: FeaturedListingService,
    private i18: I18nService,
    private snackBar: MatSnackBar,
    private propertyService: PropertyService
  ) {}

  ngOnInit(): void {
    let user = JSON.parse(localStorage.getItem("user"));
    this.isAdmin = user?.roles?.includes("ROLE_ADMIN");
    this.route.params.subscribe(params => {
      if (params.id) {
        this.id = params.id;
        this.getTenantsDetail();
        this.getBuilding();
      }
    });
  }

  getTenantsDetail() {
    this.masterTenantsService.getTenantsDetail(this.id).then((resp: any) => {
      this.data = resp;

      this.propertyService.getListingsSpotVacancy(this.id).then(vacancy => {
        this.spotVacancy = vacancy;
      });
    });
  }

  getBuilding() {
    this.masterTenantsService.getBuilding(this.id).then((resp: any) => {
      this.list = resp;
    });
  }

  editTenantDetail(tenant?, isCreate = false) {
    const dialogRef = this.dialog.open(EditDetailComponent, {
      width: "800px",
      data: {
        data: tenant,
        buildingType: this.data?.buildingType?.id,
        isAdmin: this.isAdmin,
        isCreate: isCreate,
        includedInQuarterlyStats: this.data.includedInQuarterlyStats
      }
    });

    dialogRef.afterClosed().subscribe((updatedTenant: any) => {
      if (updatedTenant) {
        const [year, month, day] = this.getLastDayOfLastQuarter();
        updatedTenant.dateEntered = new Date(
          moment.utc({ year, month: month - 1, day }).format("MM/DD/YYYY")
        );
        if (isCreate) {
          updatedTenant.building = { id: this.id };
          updatedTenant.areaUOM = { id: 9101 };
          this.list.push(updatedTenant);
        } else {
          this.list = this.list.map(tenant =>
            tenant.id === updatedTenant.id
              ? { ...tenant, ...updatedTenant }
              : tenant
          );
        }

        this.masterTenantsService
          .updateTenantDetail(this.id, this.list)
          .then((response: any) => {
            this.showMessage(
              isCreate
                ? "master-tenant.createMessage"
                : "master-tenant.updateMessage"
            );
          });
      }
    });
  }

  validateContactInformation(tenant) {
    return (
      tenant?.tenantContactName ||
      tenant?.tenantContactPhone ||
      tenant?.tenantContactEmail
    );
  }

  validateOwnersInformation(tenant) {
    return (
      tenant?.owners ||
      tenant?.ownersGroup ||
      tenant?.ownersContactName ||
      tenant?.ownersContactPhone ||
      tenant?.ownersContactEmail
    );
  }

  toggleDetailsContact(id: number): void {
    const current = this.showContactMap.get(id);
    this.showContactMap.set(id, !current);
  }

  toggleDetailsOwner(id: number): void {
    const current = this.showOwnerMap.get(id);
    this.showOwnerMap.set(id, !current);
  }

  downloadTenants() {
    this.masterTenantsService
      .downloadTenantDetail(this.id)
      .then((resp: any) => {
        if (resp.success) {
          const fileUrl1 = `${environment.apiURL}/transactionPrints/files/${resp.fileName}`;
          const fileUrl2 = `${environment.apiURL}/transactionPrints/files/${resp.excelFileName}`;

          this.downloadFile(fileUrl1, resp.fileName);
          this.downloadFile(fileUrl2, resp.excelFileName);
        }
      });
  }

  downloadFile(url: string, fileName: string) {
    const a = document.createElement("a");
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  getLastDayOfLastQuarter() {
    const now = new Date();
    const currentQuarter = Math.floor(now.getMonth() / 3);
    const lastQuarter = currentQuarter === 0 ? 3 : currentQuarter - 1;
    const year =
      currentQuarter === 0 ? now.getFullYear() - 1 : now.getFullYear();
    const lastMonth = (lastQuarter + 1) * 3 - 1;
    const lastDay = new Date(year, lastMonth + 1, 0);

    const day = String(lastDay.getDate()).padStart(2, "0");
    const month = String(lastDay.getMonth() + 1).padStart(2, "0");

    return [lastDay.getFullYear(), Number(month), Number(day)];
  }

  onDetailToggle(value: boolean): void {
    this.isDetail = value;

    if (!value) {
      this.showContactMap.clear();
      this.showOwnerMap.clear();
    }
  }

  async openVacancy() {
    let lang = this.i18.getCurrentLanguage();

    this.featureListingService.latamCountry = null;

    let res: any = await this.featureListingService
      .getSpotBuildingDetailURLFormat(this.spotVacancy[0].id, lang)
      .toPromise();

    if (res) {
      let detailsURL = `${res}`;
      detailsURL += "?scrollIntoVac=true";
      window.open(detailsURL, "_blank");
    }
  }

  showMessage(message) {
    this.snackBar.open(this.i18.get(message), "Close", {
      duration: 3000,
      horizontalPosition: "center",
      verticalPosition: "top",
      panelClass: ["custom-snackbar"]
    });
  }
}
